@media (min-width: 1700px) {
  .App .container {
    max-width: 1640px;
  }
}

@media (min-width: 1620px) {
  .App .container {
    max-width: 1560px;
  }
}

@media (min-width: 1540px) {
  .App .container {
    max-width: 1480px;
  }
}

@media (min-width: 1400px) {
  .App .container {
    max-width: 1400px;
  }
}

@media (max-width: 992px) and (min-width: 920px) {
  .App .container {
    max-width: 920px;
  }
}

.Athlete .esp-main {
  max-width: 1165px;
  margin: auto;
}

.App .float-right {
  float: right !important;
}

.App .bg-dark {
  background: #0c6ae1 !important;
}

.App .navbar {
  border-bottom: 3px solid #33363b;
  margin-bottom: 0px !important;
}

.App .navbar .navbar-brand > a {
  color: #fff;
  font-weight: 600;
  text-decoration: none;
}

.App .navbar .navbar-brand > a:visited {
  text-decoration: none;
}

.App .navbar .navbar-brand > a:hover {
  text-decoration: none;
}

.App .navbar .navbar-nav > a {
  color: #fff;
  padding-left: 15px;
}

/*Navigation*/
.App a.nav-link {
  font-weight: 300;
  letter-spacing: 1px;
}
.App a.nav-link[href="/login"] {
  margin-right: 20px;
  margin-left: 40px;
  font-weight: normal;
}

.App a.nav-link[href="/login"]:before {
  content: " ";
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #ffffff;
  position: absolute;
  margin-left: -10px;
  margin-top: 7px;
}

.App a.nav-link[href="#"]:after {
  content: " ";
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #ffffff;
  position: absolute;
  margin-left: 5px;
  margin-top: 7px;
}

.App a.nav-link[href="/signUp"] {
  border: 1px solid #fff;
  border-radius: 0.25rem;
  color: #33363b !important;
  background: #ffffff;
  font-weight: bold;
  text-align: center !important;
  padding: 0.5rem 1.25rem !important;
}

.App a.nav-link[href="/dashboard"] {
  border: 1px solid #fff;
  border-radius: 0.25rem;
  --color: #33363b !important;
  --background: #ffffff;
  font-weight: bold;
  --text-align: center !important;
  padding: 0.5rem 1.25rem !important;
  --margin-right: 10px;
  margin-left: 15px;
}

.App .ml-auto.navbar-nav a.nav-link {
  margin-right: 10px;
}

.App .ml-auto.navbar-nav a.nav-link:after {
  content: " ";
  width: 1px;
  height: 25px;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  display: inline-block;
  margin-left: 12px;
}

.App .ml-auto.navbar-nav a.nav-link:last-child:after {
  background: transparent;
}

.App .navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 1);
  border-color: rgba(255, 255, 255, 1);
}

@media (max-width: 768px) {
  .App #responsive-navbar-nav {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    margin-top: 15px;
  }
  .App a.nav-link[href="/login"] {
    margin-right: 20px;
    margin-left: 10px;
  }
  .App a.nav-link[href="/signUp"] {
    text-align: left !important;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .App a.nav-link[href="/my-profile"] {
    text-align: left !important;
    margin-top: 10px;
    margin-right: 0;
    margin-left: 0;
  }
  .App .ml-auto.navbar-nav a.nav-link:after {
    content: none;
  }
}
/*Hide Logo on small phones*/
@media (max-width: 375px) {
  .navbar-brand img {
    display: none;
  }
}

/*Footer*/
.App .footerbar {
  border-top: 5px solid #33363b;
  margin-top: 0px !important;
  color: #fff;
  min-height: 250px;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
}

.App .footer-nav > a {
  color: #fff;
}

footer p {
  font-weight: 200;
  color: rgba(255, 255, 255, 0.5);
}

@media (max-width: 768px) {
  .footer-nav a.nav-link {
    display: block;
    width: 100%;
    padding-left: 0 !important;
  }

  footer .navbar-brand img {
    display: inline-block;
  }
}

.App .padding-left {
  padding-left: 25px;
}

.App .padding-right {
  padding-right: 25px;
}