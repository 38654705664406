.ContactUs {
  padding: 80px 40px;
}

.ContactUs .lander {
  padding: 100px 0 40px 0;
  text-align: center;
}

.ContactUs .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
