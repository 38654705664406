body {
  font-family: Source Sans Pro Web,Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;
  font-size: 1.06rem;
  margin: 0;
  padding: 0;
  background-color: #e9ecef;
  color: #333;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}


@media print 
{
    @page {
      size: letter;
      margin:0;
    }
    html, body {
        width: 8.5in;
        height: 11in;
        font-size: 11px;
        background: #FFF;
        overflow:visible;
    }
    body {
        padding-top:-10mm;
    }
    .noprint { 
      display: none; 
    } 
}

h1 {
  font-weight: 700;
  line-height: 1.1;
}

h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

input[type="file"] {
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.card {
  box-shadow: 0 0 8px rgb(0 0 0 / 10%);
}

.section {
  background-color: white;
  padding: 15px 15px;
  margin: 15px 15px;
  border: 1px solid #ced4da;
  border-radius: 0.75rem;
}

body {
  font-size: 18px;
}

h1 {
  font-size: 34px;
}
h2 {
  font-size: 22px;
}
h3 {
  font-size: 21px;
}
h4 {
  font-size: 20px;
}
h5 {
  font-size: 19px;
}
h6 {
  font-size: 18px;
}

@media all and (max-device-width: 720px){
  body {
      font-size: 18px;
  }
  h1 {
    font-size: 26px;
  }
  h2 {
    font-size: 22px;
  }
  h3 {
    font-size: 21px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 19px;
  }
  h6 {
    font-size: 18px;
  }
}

@media all and (max-device-width: 640px){
  body {
      font-size: 30px;
  }
  h1 {
    font-size: 52px;
  }
  h2 {
    font-size: 34px;
  }
  h3 {
    font-size: 33px;
  }
  h4 {
    font-size: 32px;
  }
  h5 {
    font-size: 31px;
  }
  h6 {
    font-size: 30px;
  }
}

@media all and (max-device-width: 320px){
  body {
      font-size: 38px;
  }
  h1 {
    font-size: 52px;
  }
  h2 {
    font-size: 38px;
  }
  h3 {
    font-size: 37px;
  }
  h4 {
    font-size: 36px;
  }
  h5 {
    font-size: 35px;
  }
  h6 {
    font-size: 34px;
  }
}
