@media all and (min-width: 480px) {
  .ForgotPassword {
    padding: 60px 0;
  }

  .ForgotPassword form {
    margin: 0 auto;
    max-width: 640px;
  }
}

.ForgotPassword {
  padding: 100px 0 40px 0;
}

.ForgotPassword .msg-center {
  margin: 0;
}