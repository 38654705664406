.Dashboard .lander {
  padding: 100px 0 40px 0;
  text-align: center;
}

.Dashboard .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Dashboard .card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid gray;
  border-radius: 0.25rem;
}

.Dashboard .card-title {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0.5rem;
  font-weight:bolder;
  font-size: larger;
}

.Dashboard .card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0.5rem;
  text-align: center;
}

.Dashboard .card-header {
  padding: 0.5rem 0.5rem;
  margin-bottom: 0;
  color: inherit;
  background-color: rgba(0, 0, 0, 0.01);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.Dashboard .card-footer {
  padding: 0.5rem 0.5rem;
  background-color: rgba(0, 0, 0, 0.01);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.Dashboard .card-col {
  padding-bottom: 30px;
  padding-right: 15px;
  padding-left: 15px;
}

.Dashboard .card-col a {
  color: black;
  text-decoration: none;
}

.Dashboard .card-col a:visited {
  text-decoration: none;
}

.Dashboard .card-col a:hover {
  text-decoration: none;
}

.Dashboard .card-body-heading {
  font-size: 145%;
  font-weight: 600;
}

.Dashboard .float-right {
  float: right !important;
}

.Dashboard .pageImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;
}

.Dashboard .bold {
  font-weight: 600;
}

.Dashboard .buttonSpace {
  margin-left: 2px;
}

.Dashboard .card-row {
  padding-left: 15px;
  padding-right: 15px;
}

.Dashboard .card-img-top {
  height: 100%;
  width: 100%;
  object-fit: contain;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-color: #eaeaea !important;
}

@media (min-width: 576px) {
  .Dashboard .card-img-top {
    height: 400px;
  }
}
@media (min-width: 768px) {
  .Dashboard .card-img-top {
    height: 400px;
  }
}
@media (min-width: 992px) {
  .Dashboard .card-img-top {
    height: 350px;
  }
}
@media (min-width: 1200px) {
  .Dashboard .card-img-top {
    height: 350px;
  }
}
@media (min-width: 1540px) {
  .Dashboard .card-img-top {
    height: 300px;
  }
}

.Dashboard .card-footer-btn {
  margin-left: 2px;
  color: #fff !important;
  width: 100%;
}