.Athlete {
  padding: 120px 0 20px 0;
}

.Athlete form textarea {
  height: 300px;
  font-size: 145%;
}

.Athlete h4 {
  font-weight: bolder;
}

.Athlete h5 {
  font-weight: bold;
}

.Athlete .bold  {
  font-weight: bold;
}

.Athlete div b  {
  font-weight: bold;
}

@media (min-width: 768px) {
  .Athlete div b {
    float: right;
  }
}

.Athlete .large-txt {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
  display: block;
}

.Athlete .width-inherit {
  width: inherit;
  max-width: 1000px;
  display: block;
  margin: 0 auto;
}

.Athlete .profile-img {
  max-width: 100%;
}

.Athlete .profile a {
  color: #212529;
}

.Athlete .img-fluid {
  max-height: 475px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}

.Athlete .button {
  border: 2px solid #0c6ae1;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-left: 20px;
width: 100%
}

.Athlete .button:hover {
  background-color: #0c6ae1;
  color: white;
} 

.Athlete .pad-bottom-5px {
  padding-bottom: 5px;
} 

.Athlete .img-profile {
  height: 100%;
  width: 100%;
  object-fit: contain;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-color: #eaeaea !important;
} 