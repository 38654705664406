.AthleteLinkTree {
  padding: 100px 0 40px 0;
}

.AthleteLinkTree form textarea {
  height: 300px;
  font-size: 145%;
}

.AthleteLinkTree h4 {
  font-weight: bolder;
}

.AthleteLinkTree h5 {
  font-weight: bold;
}

.AthleteLinkTree .bold  {
  font-weight: bold;
}

.AthleteLinkTree div b  {
  font-weight: bold;
}

@media (min-width: 768px) {
  .AthleteLinkTree div b {
    float: right;
  }
}

.AthleteLinkTree .large-txt {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
  display: block;
}

.AthleteLinkTree .width-inherit {
  width: inherit;
}

.AthleteLinkTree .profile-img {
  max-width: 500px;
  max-height: 300px;
}

.AthleteLinkTree .img-fluid {
  max-height: 800px;
  width: auto;
}

.AthleteLinkTree .button {
  border: 2px solid #0c6ae1;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-left: 20px;
width: 100%
}

.AthleteLinkTree .button:hover {
  background-color: #0c6ae1;
  color: white;
} 

.AthleteLinkTree .pad-bottom-5px {
  padding-bottom: 5px;
} 