.TeamEdit {
  padding: 100px 0 40px 0;
}

.TeamEdit .lander {
  padding: 10px 0;
  text-align: center;
}

.TeamEdit .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.TeamEdit form label {
  font-weight: 600;
}

@media (min-width: 1200px) {
  .TeamEdit form label {
    float: right;
  }
  .modal-content form label {
    font-weight: 600;
    float: right;
  }
}

.TeamEdit form input {
  width: 100%;
}

.TeamEdit form select {
  width: 100%;
}

.TeamEdit textarea {
  height: 150px;
  width: 100%;
}

.textarea {
  height: 150px;
  width: 100%;
}

.center {  
  display: flex;  
  justify-content: center;  
  align-items: center;  
  text-align: center;
}  

.TeamEdit form .btn-lg {
  width: 80%;
  margin: auto;  
}

.TeamEdit p .validation {
  color: rgb(88, 2, 2);
}

.modal-90w {
  width: 98%;
  max-width: none !important;
}

.modal-content form input {
  width: 100%;
}

.modal-content form label {
  font-weight: 600;
}
