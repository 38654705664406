.Admin .lander {
  padding: 100px 0 40px 0;
  text-align: center;
}

.Admin .lander h1 {
  color: #0c6ae1; 
  text-shadow: 1px 1px #000;
  font-family: 'Open Sans Condensed', sans-serif; 
  font-size: 38px; 
  font-weight: 600; 
  line-height: 64px; 
  margin: 0 0 0; 
  padding: 20px 30px; 
  text-align: center; 
  text-transform: uppercase;
}

.Admin .lander h3 {
  color: #111; 
  font-family: 'Open Sans Condensed', sans-serif; 
  font-size: 32px; 
  font-weight: 700; 
  margin: 0 0 0; 
  text-align: center; 
}

.Admin .lander h4 {
  color: #111; 
  font-family: 'Open Sans Condensed', sans-serif; 
  font-size: 26px; 
  font-weight: 700; 
  margin: 0 0 0; 
  text-align: center;
}

.Admin .card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid gray;
  border-radius: 0.25rem;
}

.Admin .card-title {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0.5rem;
  font-weight:bolder;
  font-size: larger;
}

.Admin .card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0.5rem;
}

.Admin .card-header {
  padding: 0.5rem 0.5rem;
  margin-bottom: 0;
  color: inherit;
  background-color: rgba(0, 0, 0, 0.01);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.Admin .card-footer {
  padding: 0.5rem 0.5rem;
  background-color: rgba(0, 0, 0, 0.01);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.Admin .card-col {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 15px;
  padding-left: 15px;
}

.Admin .card-col a {
  color: black;
  text-decoration: none;
}

.Admin .card-col a:visited {
  text-decoration: none;
}

.Admin .card-col a:hover {
  text-decoration: none;
}

.Admin .card-body-heading {
  font-size: 145%;
  font-weight: 600;
}

.Admin .float-right {
  float: right !important;
}

.Admin .pageImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;
}

.Admin .bold {
  font-weight: 600;
}

.Admin .buttonSpace {
  margin-left: 2px;
}

.Admin .card-row {
  padding-left: 15px;
  padding-right: 15px;
}

.Admin .card-img-top {
  height: 100%;
  width: 100%;
  object-fit: contain;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-color: #eaeaea !important;
}

@media (min-width: 576px) {
  .Admin .card-img-top {
    height: 400px;
  }
}
@media (min-width: 768px) {
  .Admin .card-img-top {
    height: 400px;
  }
}
@media (min-width: 992px) {
  .Admin .card-img-top {
    height: 350px;
  }
}
@media (min-width: 1200px) {
  .Admin .card-img-top {
    height: 350px;
  }
}
@media (min-width: 1540px) {
  .Admin .card-img-top {
    height: 300px;
  }
}

.Admin .card-footer-btn {
  margin-left: 2px;
  color: #fff !important;
  width: 100%;
}

.Admin .property-card--address-pin-and-address {
  display: flex;
  align-items: stretch;
  flex-grow: 1;
  vertical-align: middle;
}

.Admin .property-card--address {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Admin .dot {
  height: 7px;
  width: 7px;
  background-color: #ff5a09;
  border-radius: 50%;
  display: inline-block;
}
