.TeamPrint .lander {
  padding: 100px 0 40px 0;
  text-align: center;
}

.TeamPrint .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.TeamPrint .card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid gray;
  border-radius: 0.25rem;
}

.TeamPrint .card-title {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  font-weight:bolder;
  margin-bottom: 0rem;
}

.TeamPrint .card-text {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin-bottom: 0rem;
  font-size: smaller;
}

.TeamPrint .card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0.5rem;
}

.TeamPrint .card-header {
  padding: 0.5rem 0.5rem;
  margin-bottom: 0;
  color: inherit;
  background-color: rgba(0, 0, 0, 0.01);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.TeamPrint .card-footer {
  padding: 0.5rem 0.5rem;
  background-color: rgba(0, 0, 0, 0.01);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.TeamPrint .card-col {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px;
}

.TeamPrint .card-col a {
  color: black;
  text-decoration: none;
}

.TeamPrint .card-col a:visited {
  text-decoration: none;
}

.TeamPrint .card-col a:hover {
  text-decoration: none;
}

.TeamPrint .card-body-heading {
  font-size: 145%;
  font-weight: 600;
}

.TeamPrint .float-right {
  float: right !important;
}

.TeamPrint .pageImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;
}

.TeamPrint .bold {
  font-weight: 600;
}

.TeamPrint .buttonSpace {
  margin-left: 2px;
}

.TeamPrint .card-row {
  padding-left: 15px;
  padding-right: 15px;
}

.TeamPrint .card-img-top {
  height: 100%;
  width: 100%;
  object-fit: contain;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-color: #eaeaea !important;
}

@media (min-width: 576px) {
  .TeamPrint .card-img-top {
    height: 150px;
  }
}
@media (min-width: 768px) {
  .TeamPrint .card-img-top {
    height: 150px;
  }
}
@media (min-width: 992px) {
  .TeamPrint .card-img-top {
    height: 150px;
  }
}
@media (min-width: 1200px) {
  .TeamPrint .card-img-top {
    height: 350px;
  }
}
@media (min-width: 1540px) {
  .TeamPrint .card-img-top {
    height: 300px;
  }
}

.TeamPrint .card-footer-btn {
  margin-left: 2px;
  color: #fff !important;
  width: 100%;
}

.TeamPrint .property-card--address-pin-and-address {
  display: flex;
  align-items: stretch;
  flex-grow: 1;
  vertical-align: middle;
}

.TeamPrint .property-card--address {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.TeamPrint .dot {
  height: 7px;
  width: 7px;
  background-color: #ff5a09;
  border-radius: 50%;
  display: inline-block;
}

@media print 
{
  .TeamPrint .card-title {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    font-weight:bolder;
    margin-bottom: 0rem;
  }
  
  .TeamPrint .card-text {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    margin-bottom: 0rem;
    font-size: small;
  }

  .TeamPrint .pagebreak {
    /* clear: both; */
    page-break-after: always;
  }
}

.TeamPrint .col-8 {
  padding-top: 5px;
  padding-right: 5px;
  padding-left: 0px;
}
.TeamPrint .col-4 {
  padding-right: 11px;
  padding-left: 0px;
}